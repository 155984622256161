import React, { useState } from "react";
import { Avatar, Badge, Box, Button, Typography, Popover, Divider, List, ListItem } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { useNavigate } from "react-router-dom";

const RightMenu = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const navigate = useNavigate();
  const username = localStorage.getItem("username");

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("username");
    localStorage.removeItem("isLoggedIn");
    localStorage.clear();
    sessionStorage.clear();
    navigate("/");
  };

  const handleNotificationClick = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        gap: 3,
      }}
    >
      <Badge color="success" variant="dot">
        <NotificationsNoneIcon
          sx={{ color: "white", fontSize: "35px", cursor: "pointer" }}
          onClick={anchorEl ? handleClose : handleNotificationClick}
        />
      </Badge>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        sx={{
          marginLeft: 2,
        }}
      >
        <Box
          sx={{
            border: "2px solid white",
            p: 2,
            width: "20vw",
            color: "white",
            backgroundColor: "#8D8DDA",
            // backgroundColor: "#2B2E7D",
          }}
        >
          <Typography>Notifications</Typography>
          <Divider sx={{ backgroundColor: "white", marginY: 1 }} />
          <Box component="ul" sx={{ paddingLeft: 4, margin: 0 }}>
            <Typography component="li" sx={{ marginBottom: 1 }}>
              Welcome to OnyxProvider.
            </Typography>
            <Typography component="li" sx={{ marginBottom: 1 }}>
              If you encounter any error, please contact the support team.
            </Typography>
          </Box>
        </Box>
      </Popover>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 3,
        }}
      >
        <Avatar
          alt="Physician"
          sx={{
            width: 35,
            height: 35,
            backgroundColor: "#d9d9d91a",
            border: "1.5px solid #8D8DDA",
            "&:hover": {
              cursor: "pointer",
            },
          }}
          onClick={() => setOpenMenu(!openMenu)}
        />
        {openMenu && (
          <Button
            variant="text"
            onClick={handleLogout}
            sx={{ padding: 0, m: 0 }}
          >
            <Typography variant="h6" sx={{ letterSpacing: 7 }}>
              Logout
            </Typography>
          </Button>
        )}
      </Box>
      <Typography variant="body2" sx={{ color: "white" }}>{`Dr. ${username
        .split(".")[0]
        .charAt(0)
        .toUpperCase()}${username.split(".")[0].slice(1)}  ${username
        .split(".")[1]
        .charAt(0)
        .toUpperCase()}${username.split(".")[1].slice(1)}`}</Typography>
        {/* <Box
          component="img"
          sx={{
            height: 50,
            width: 200,
            // maxHeight: { xs: 100, md: 100 },
            // maxWidth: { xs: 100, md: 100 },
          }}
        alt="logo"
        src={process.env.REACT_APP_LOGO}
  /> */}
    </Box>
  );
};

export default RightMenu;
